import http from "../http-common";

class ScholarService {
  createscholar(data) {
    return http.post('/scholar', data);
  }
  getscholars(id) {
    return http.get(`/getresearch?user_id=`+id);
  }
  getscholar(id) {
    return http.get(`/scholar/` + id);
  }
  updatescholar(id, data) {
    return http.put(`/scholar/${id}`, data);
  }
  deletescholar(id) {
    return http.delete(`/scholar/` + id);
  }
}

export default new ScholarService();