import Vue from "vue";
import Router from "vue-router";
import Login from './components/Login.vue';
import Users from './components/Users.vue';
import Menu from './components/Menu.vue';
import Menumanage from './components/Menumanage.vue';
import Research from './components/Research.vue';

Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    
    {
      path: "/Research",
      alias: "/Research",
      name: "Research",
      component: Research
    },
    
    {
      path: "/login",
      alias: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/Users",
      alias: "/Users",
      name: "Users",
      component: Users
    },
    {
      path: "/menu",
      alias: "/menu",
      name: "menu",
      component: Menu
    },
    {
      path: "/menumanage",
      alias: "/menumanage",
      name: "menumanage",
      component: Menumanage
    },
  ]
});


// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login','/home','/qrcode','/main'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('tmd');
//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   // console.log(loggedIn);
//   if (authRequired && !loggedIn) {
//     next('/home');
//     // next();
//   } else {
//     next();
//   }

// });

export default router;
