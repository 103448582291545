<template>
  <div class="container">
    <!-- https://scholar.google.com/citations?hl=th&user=HyrIVzQAAAAJ -->
    <div class="mb-3 mt-5">
      <label for="exampleFormControlInput1" class="form-label">URL</label>
      <input
        type="text"
        v-model="url"
        class="form-control"
        id="exampleFormControlInput1"
        placeholder="https://scholar.google.com/citations?hl=th&user=mKETtsEAAAAJ"
      />
    </div>
    <button type="button" class="btn btn-primary" @click="searchdata()">
      Search
    </button>&nbsp;
    <button type="button" class="btn btn-danger" @click="clear()">
      Clear
    </button>
    <div v-if="descriptionstatus">
       <div style="text-align: right" class="mt-3">
      <button @click="exportTableToExcel('tblData')" class="btn btn-success">
        <i class="fa fa-file-excel-o"></i> Export
      </button>
    </div>
    <div class="gsc_lcl" role="main" id="gsc_prf_w">
      <div id="gsc_prf">
        <div id="gsc_prf_pu">
          <div id="gsc_prf_pua" class="gs_rimg">
            <img
              sizes="print 80px,128px"
              :src="description.photo"
              id="gsc_prf_pup-img"
            />
          </div>
        </div>
        <div id="gsc_prf_i">
          <div id="gsc_prf_inw">
            <div id="gsc_prf_in">{{ description.name }}</div>
          </div>
          <div class="gsc_prf_il">
            {{ description.affiliations }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <table class="table mt-3">
        <thead>
        <tr>
          <th style="font-size: 14px">บทความ</th>
          <th style="font-size: 14px">อ้างโดย</th>
          <th style="font-size: 14px; width: 10px">ปี</th>
        </tr>
        </thead>
        <tbody>
          <tr v-for="(l, i) in description.articles" :key="i + 1">
            <td class="gsc_a_t">
              <a
              target="_blank"
                :href="l.link"
                class="gsc_a_at"
                >{{ l.title }}</a
              >
              <div class="gs_gray">
                {{ l.authors }}
              </div>
              <div class="gs_gray">
                {{ l.publication }}&nbsp;<span class="gs_oph">, {{ l.year }}</span>
              </div>
            </td>
            <td style="font-size: 14px">
            <a style="text-decoration: none;" target="_blank" :href="l.citedBy.link">{{ l.citedBy.cited }}</a>  
            </td>
            <td style="width: 20px; font-size: 14px">{{ l.year }}</td>
          </tr>
        </tbody>
      </table>
   
    </div>
    </div>
   

    <div id="dvData">
      <table
        class="table mt-5"
        id="tblData"
        ref="exportable_table"
        border="1"
        style="border-collapse: collapse;display: none;"
      >
        <tr>
          <th style="font-size: 14px">บทความ</th>
          <th style="font-size: 14px">ผู้วิจัย</th>
          <th style="font-size: 14px">อ้างโดย</th>
          <th style="font-size: 14px;">ปี</th>
        </tr>
        <tbody>
          <tr v-for="(l, i) in description.articles" :key="i + 1">
            <td style="font-size: 14px">
              {{ l.title }}
            </td>
            <td style="font-size: 14px">
              {{ l.authors }}
            </td>
            <td style="font-size: 14px">
              {{ l.citedBy.cited }}
            </td>
            <td style="width: 20px; font-size: 14px">{{ l.year }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script src="xlsx-js-style"></script>

<script>
// import * as XLSX from "xlsx/xlsx.mjs";
import draggable from "vuedraggable";
import ScholarService from "../services/ScholarService";
import * as XLSX from "xlsx-js-style";

export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {
    draggable,
  },
  data() {
    return {
      url: "",
      list: [],
      description:{},
      descriptionstatus:false
    };
  },
  mounted() {
    console.log(this.description);
  },
  methods: {
    clear(){
this.url = '',
this.description = {}
this.descriptionstatus = false
    },
    searchdata() {
      if (this.url == null || this.url == "") {
        alert("กรุณากรอก URL");
      } else {
        var link = this.url.split("user=");
        var userId = link[1].split("&hl=");
        userId = userId[0]
        if (userId) {
          ScholarService.getscholars(userId).then((res) => {
          this.description = res.data;
          this.list = this.description.articles;
          console.log(this.description);
          console.log(this.list);
          if (this.description) {
          this.descriptionstatus = true
            
          }
        });
        }else{
          alert('กรุณากรอบ URL ให้ถูกต้อง')
        }
        console.log(userId);
        
      }
    },
    exportTableToExcel() {
      const table = document.querySelector("#dvData table");

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet([]);
      const rows = table.rows;
      // console.log(rows);
      for (let i = 0; i < rows.length; i++) {
        const rowData = [];
        const cells = rows[i].cells;
        for (let j = 0; j < cells.length; j++) {
          const cell = cells[j];

          // attention here
          const nestedTable = cell.querySelector("table");
          if (nestedTable) {
            const nestedRows = nestedTable.rows;
            console.log(nestedRows);
            for (let k = 0; k < nestedRows.length; k++) {
              const nestedCells = nestedRows[k].cells;
              const nestedRowData = [];
              for (let l = 0; l < nestedCells.length; l++) {
                const nestedCell = nestedCells[l];
                nestedRowData.push(nestedCell.innerText);
              }
              rowData.push(...nestedRowData);
            }
          } else {
            // console.log(1);
            rowData.push(cell.innerText);
          }
        }
        XLSX.utils.sheet_add_aoa(worksheet, [rowData], { origin: -1 });
      }
      for (var i in worksheet) {
        // console.log(i);
        if (typeof worksheet[i] != "object") continue;
        let cell = XLSX.utils.decode_cell(i);
        // console.log(cell.c);
        worksheet[i].s = {
          // styling for all cells
          font: {
            name: "cordia new",
            sz: 16,
          },
          alignment: {
            vertical: "left",
            horizontal: "left",
            wrapText: "1", // any truthy value here
          },
          border: {
            right: {
              style: "thin",
              color: "000000",
            },
            left: {
              style: "thin",
              color: "000000",
            },
            top: {
              style: "thin",
              color: "000000",
            },
            bottom: {
              style: "thin",
              color: "000000",
            },
          },
        };
        if ( i == 'A1'||i == 'B1'||i == 'C1' ||i == 'D1' ) {
 //height for row 2
      worksheet[i].s.font= {
        name:'cordia new',
    sz: '16',
    bold:true
  }
  worksheet[i].s.alignment= {
    vertical: 'center',
    horizontal: 'center',
    wrapText: '0', // any truthy value here
  }
}
      }
      var wscols = [{ wch: 100 },{ wch: 100 }, { wch: 20 }, { wch: 20 }];

      worksheet["!cols"] = wscols;
      // console.log(worksheet["A3"]);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      const excelFile = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "binary",
      });
      // console.log(workbook);
      var filename = this.description.name + ".xlsx";

      const downloadLink = document.createElement("a");
      downloadLink.href = `data:application/octet-stream;base64,${btoa(
        excelFile
      )}`;
      downloadLink.download = filename;
      downloadLink.click();
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.gs_gray {
    color: #777777;
}
</style>
